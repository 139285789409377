/** @jsx jsx */
import Article from '../../../template/article'
import { jsx } from '@emotion/core'

const ArticlePage = ({ location }) => (
  <Article location={location}>
    <p>
      僕は JavaScript が好きです。だから書いてます。JavaScript は、めちゃめちゃ拡がりと可能性を感じるんですよね。サーバーサイドも書けて、フロントエンドも書ける。それってJavaScript さえ知ってれば何でもできるってことじゃないですか。
    </p>
    <p>
      今に始まったことではありませんが、Web に求められる要件は変わり続けていると思うんです。始まりは情報の閲覧が求められる要件だったかもしれませんが、データの送信ができるようになり情報の閲覧だけでなく書き換えもできるようになった。そうなるとできることってめちゃめちゃ増えるわけですよ。そんなときにHTML(記述) と CSS(装飾) だけじゃ物足りないわけですよね。情報の書き込みらしい振る舞いを表現したいわけです。そこで JavaScript。どうですか。jQuery？良いじゃないですか。ほんとに JavaScript のおかげで Web は情報を一方的に表示するだけでなく、双方向的な表現の手段になった。もう僕はこの時点で JavaScript 大好きです。大好きなんですよね。
    </p>
    <p>
      更に月日が流れ、Web の表現に求められる要件もどんどん増えてきた。アプリケーションさながらの動きもさせたい、どうしよう。今まではとりあえず jQuery 使えばよかったわけです。彼は JavaScript で可能な振る舞いの記述の複雑さを隠蔽し、誰でも簡単に双方向的な表現を可能にした。最高じゃないですか。本当にありがたいわけです。ただ少し問題も多かった。そこで React や Angular のようなモダンなフレームワークの登場です。彼らの登場で大規模アプリケーションでも JavaScript の表現をどんどん扱いやすくなった。最高じゃないですか？
    </p>
    <p>
      もうね、JavaScript 書かない理由がないんですよ。ただ、これは実はフロントエンドの話。エンジニアやってるとどうしてもサーバーサイドで動く言語も知っとかないといけないわけです。さあどうしましょうかと。言わずもがなNode.js ですよ。なんであなたサーバーサイドで動いちゃうの、最高じゃないの。
    </p>
    <p>
      もちろん、このまま JavaScript  がすべてを解決し続けるとは思わないし、これからJavaScript を代替する存在が現れる可能性なんて存分にある。でもね、今のこの JavaScript の勢い、未完成であることを自覚し続け変わり続ける姿、見ていて本当に気持ちいいのよ。ずっと書き続けたいって思っちゃう。
    </p>
    <p>
      これって車や電車って手段があるのに自転車をわざわざ購入して乗っちゃう気持ちと似てない？似てないって？似てるってことでいいじゃないですか。 JavaScript は自転車みたいなもんですよ。どこへでも連れてってくれる。僕は自転車好きです。
    </p>
    <p><b>JavaScriptは自転車みたいなもん</b></p>
  </Article>
)

export default ArticlePage
